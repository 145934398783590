import React, { useState, ChangeEvent, FocusEvent, FormEvent, useCallback, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import keycloak from '../config/keycloakConfig'; 
import Swal from 'sweetalert2';
import { createSubscription, updateSubscription } from '../services/subscription';

interface SubscriptionData {
  SubscriptionType: string;
  price: number;
  Duration: string;
  Description: string;
}

interface Errors {
  SubscriptionType?: string;
  price?: string;
  Duration?: string;
  Description?: string;
}

function SubscriptionForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const gymId = location.state?.gymId;
  const subscription = location.state?.subscription;
  const subscriptionId = location.state?.subscriptionId; 
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [SubscriptionData, setSubscriptionData] = useState<SubscriptionData>({
    SubscriptionType: '',
    price: 0,
    Duration: '',
    Description: ''
  });

  const [errors, setErrors] = useState<Errors>({});
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({});
  const [success, setSuccess] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isModified, setIsModified] = useState<boolean>(false); // Track if data is modified

  // Set subscription data for editing when the component mounts
  useEffect(() => {
    if (subscription) {
      setSubscriptionData({
        SubscriptionType: subscription.subscriptionName,
        price: subscription.price,
        Duration: String(subscription.duration),
        Description: subscription.description
      });
    }
  }, [subscription]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      setSubscriptionData((prevData) => ({ ...prevData, [name]: value }));
      validate({ ...SubscriptionData, [name]: value });
      setIsModified(true); // Mark as modified when input changes
    },
    [SubscriptionData]
  );

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name } = e.target;
      setTouched((prevTouched) => ({ ...prevTouched, [name]: true }));
      validate(SubscriptionData);
    },
    [SubscriptionData]
  );

  const validate = useCallback((data: SubscriptionData) => {
    let tempErrors: Errors = {};
    if (!data.SubscriptionType) tempErrors.SubscriptionType = 'Subscription Type is required';
    if (!data.Duration) tempErrors.Duration = 'Duration is required';
    if (data.price <= 0) tempErrors.price = 'Price must be greater than zero';

    setErrors(tempErrors);
    setIsFormValid(Object.keys(tempErrors).length === 0);
  }, []);

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!isFormValid) {
        return;
      }
  
      const userId = keycloak.tokenParsed?.sub;
      if (!userId) {
        Swal.fire({
          icon: 'error',
          title: 'User ID not found',
          text: 'Unable to retrieve user information. Please try again.',
        });
        return;
      }
  
      const newSubscription = {
        subscriptionName: SubscriptionData.SubscriptionType,
        description: SubscriptionData.Description,
        price: SubscriptionData.price,
        duration: Number(SubscriptionData.Duration),
        createdBy: userId,
      };
  
      try {
        let response;
  
        // Check if subscriptionId is available for update
        if (subscriptionId && gymId) {
          // Update the existing subscription without checking gymId
          response = await updateSubscription(newSubscription, subscriptionId);
        } else if (gymId) {
          // Create a new subscription if gymId is available
          response = await createSubscription(newSubscription, gymId);
        } else {
          throw new Error('Gym ID is not available for creating a new subscription');
        }
  
        console.log('Subscription operation successful:', response);
  
        Swal.fire({
          icon: 'success',
          title: subscriptionId ? 'Subscription Updated' : 'Subscription Created',
          text: 'The subscription plan has been successfully saved.',
        });
  
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          navigate('/add-gym');
        }, 3000);
      } catch (error) {
        console.error('Error saving subscription:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to save subscription. Please try again later.',
        });
      }
    },
    [isFormValid, SubscriptionData, gymId, subscriptionId, navigate] // Ensure subscriptionId is included in dependencies
  );
  

  const handleCancel = useCallback(() => {
    navigate('/add-gym');
  }, [navigate]);

  return (
    <div className="container mx-auto p-10">
      <form className="bg-white p-8 rounded-lg shadow-md" onSubmit={handleSubmit}>
        <h2 className="text-2xl font-bold mb-6">{subscription ? 'EDIT SUBSCRIPTION PLAN' : 'ADD NEW SUBSCRIPTION PLAN'}</h2>

        {success && <div className="bg-green-500 text-white p-3 rounded mb-4">SUCCESSFULLY SAVED</div>}

        {/* Subscription Plan */}
        <div className="flex flex-col mb-4">
          <label className="mb-2 font-medium">
            Plan Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="SubscriptionType"
            value={SubscriptionData.SubscriptionType}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`basic-input border p-2 rounded ${errors.SubscriptionType && touched.SubscriptionType ? 'border-red-500' : ''}`}
          />
          {errors.SubscriptionType && touched.SubscriptionType && (
            <span className="text-red-500 text-sm">{errors.SubscriptionType}</span>
          )}
        </div>

        {/* Duration */}
                <div className="flex flex-col mb-4">
          <label className="mb-2 font-medium">
            Duration <span className="text-red-500">*</span>
          </label>
          <div className="relative">
            <input
              type="number"
              name="Duration"
              value={SubscriptionData.Duration}
              onChange={handleChange}
              onBlur={handleBlur}
              className={`basic-input border p-2 pr-12 rounded w-full ${errors.Duration && touched.Duration ? 'border-red-500' : ''}`}
            />
            <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">months</span>
          </div>
          {errors.Duration && touched.Duration && (
            <span className="text-red-500 text-sm">{errors.Duration}</span>
          )}
        </div>


        {/* Description */}
        <div className="flex flex-col mb-4">
          <label className="mb-2 font-medium">
            Description
          </label>
          <textarea
            name="Description"
            value={SubscriptionData.Description}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`basic-input border p-2 rounded ${errors.Description && touched.Description ? 'border-red-500' : ''}`}
            rows={4}
          />
          {errors.Description && touched.Description && (
            <span className="text-red-500 text-sm">{errors.Description}</span>
          )}
        </div>

        {/* Price */}
        <div className="flex flex-col mb-4">
          <label className="mb-2 font-medium">
            Price <span className="text-red-500">*</span>
          </label>
          <div className="relative">
            <input
              type="number"
              name="price"
              value={SubscriptionData.price}
              onChange={handleChange}
              onBlur={handleBlur}
              className={`p-2 pl-12 border ${errors.price && touched.price ? 'border-red-500' : 'border-gray-300'} rounded-lg w-full`}
              placeholder="Enter price"
            />
            <span className="absolute left-3 top-2 text-gray-500">SAR</span>
          </div>
          {errors.price && touched.price && (
            <span className="text-red-500 text-sm">{errors.price}</span>
          )}
        </div>

        {/* Buttons Section */}
        <div className="flex justify-start space-x-4">
          <button
            type="submit"
            className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
            disabled={!isFormValid || !isModified} // Disable if form is not valid or not modified
          >
            {subscriptionId ? 'Update' : 'Save'} {/* Conditional button text */}
          </button>

          <button
            type="button"
            onClick={handleCancel}
            className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

export default SubscriptionForm;
