import React, { useEffect, useState } from 'react';
import './Sidebar.css';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import profilePicture from '../assets/profile.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import welcome from '../assets/welcomeI.jpg';
import { useNavigate } from 'react-router-dom';
import GymCard from './GymCard';
import './GymCard.css';
import { getGymGroupsByUserId } from '../services/gymBusinessService';
import keycloak from '../config/keycloakConfig';
import { deleteGymById } from '../services/gymBusinessService';
import Swal from 'sweetalert2'; 
import TablePagination from '@mui/material/TablePagination';

// Define the type for the gym object
interface Gym {
  id: number;
  image: string;
  name: string;
  description: string;
  location?: string; // Make location optional
}

const AddGym: React.FC = () => {
  const navigate = useNavigate();
  const [gyms, setGyms] = useState<Gym[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(6);

  // Fetch gym data from the backend
  useEffect(() => {
    const userId = keycloak.tokenParsed?.sub;
  
    if (!userId) {
      setError('User ID is not available.');
      setLoading(false);
      return;
    }
  
    const loadGyms = async () => {
      try {
        setLoading(true);
        const fetchedGyms = await getGymGroupsByUserId(userId) as Gym[];
  
        const transformedGyms = fetchedGyms.map((gym: any) => {
          // Filter out any empty or invalid image URLs
          const validImages = gym.images?.filter((img: any) => img.imageUrl && img.imageUrl.trim() !== '') || [];
  
          return {
            id: gym.id,
            name: gym.gymGroupName,
            description: gym.description,
            image: validImages.length > 0 ? validImages[0].imageUrl : '', // Set the first image as the main one
            images: validImages, // Include all valid images
            location: gym.country || 'Unknown location',
          };
        });
  
        setGyms(transformedGyms);
      } catch (error) {
        setError('Failed to fetch gyms. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
  
    loadGyms();
  }, [keycloak]);
  

  // Handle page change
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Paginated data
  const paginatedGyms = gyms.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

// Handle deletion of a gym
const handleDelete = async (id: number) => {
  // Show a confirmation alert using SweetAlert
  const result = await Swal.fire({
    title: 'Are you sure?',
    text: "This action cannot be undone. Do you want to proceed?",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, cancel!',
  });

  // If the user confirmed the deletion
  if (result.isConfirmed) {
    try {
      await deleteGymById(id); // Call the deleteGymById method with the appropriate ID
      setGyms(gyms.filter(gym => gym.id !== id)); // Update the gyms state to remove the deleted gym
      // Show a success alert
      Swal.fire(
        'Deleted!',
        'Gym has been deleted successfully.',
        'success'
      );
    } catch (error) {
      setError('Failed to delete gym. Please try again later.');
    }
  }
};

  return (
    <React.Fragment>
      <div className="header">
        <input type="text" placeholder="Search" className="search-bar" />
        <div className="header-right">
          <button className="add-gym-button" onClick={() => navigate('/add-gym-business')}>
            ADD GYM
          </button>
          <FontAwesomeIcon icon={faBell} className="icon-bell" />
          <img src={profilePicture} alt="Profile" className="profile-picture" />
        </div>
      </div>

      <div className="flex justify-between mt-5">
        <div
          className="flex-1 bg-white bg-cover bg-center bg-no-repeat rounded-lg shadow-md ml-8 p-6 text-white"
          style={{ backgroundImage: `url(${welcome})` }}
        >
          <h2 className="text-2xl font-bold mb-4">WELCOME BACK.....</h2>
          <p className="text-lg">
            Welcome back, Admin! Ready to manage and take your operations to the next level? Let's get started!
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-md text-center w-64 ml-4 p-4">
          <p className="text-red-500 text-sm">Hi <span>User...</span></p>
          <img src={profilePicture} alt="Profile" className="w-14 h-14 rounded-full mx-auto my-2" />
          <p className="text-lg font-bold">MASTER</p>
          <p className="text-gray-700 mt-4">Active Gyms: {gyms.length}</p>
        </div>
      </div>

      
      <div className="gym-cards-container">
        {loading ? (
          <p>Loading gyms...</p>
        ) : error ? (
          <p>{error}</p>
        ) : paginatedGyms.length > 0 ? (
          paginatedGyms.map((gym) => (
            <GymCard 
              key={gym.id} 
              gym={gym} 
              onDelete={handleDelete} // Pass the handleDelete function to GymCard
            />
          ))
        ) : (
          <p>No gyms found.</p>
        )}
      </div>

      {/* Pagination controls */}
      <TablePagination
        component="div"
        count={gyms.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[6, 12, 24]} // Customize the options as needed
      />
    </React.Fragment>
  );
};

export default AddGym;
